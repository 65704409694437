import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid,
    Select,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Typography, Box, Button, Divider, Tab
} from '@mui/material';
import { TableFilterContainer } from 'src/components/table';
import {
    DefaultPaginationData,
    getSelectOptions
} from 'src/constants/index';
import {CommonService, IdentityService, NumberService, PortingService} from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';
import BaseButton from "../../../components/buttons/BaseButton";
import { makeStyles } from "@mui/styles";
import LinearProgress, {linearProgressClasses, LinearProgressProps} from "@mui/material/LinearProgress";
import {useNavigate} from "react-router-dom";
import BaseCard from "../../../components/BaseCard";
import {useStore} from "../../../store/Store";
import BaseSelect from "../../../components/BaseSelect";
import BaseTextField from "../../../components/BaseTextField";
import { LoadingButton } from '@mui/lab';
import BaseSearchableSelect from 'src/components/BaseSearchableSelect';
import TemplateFilesForm from 'src/components/TemplateFilesForm';

const useStyles = makeStyles((theme) => ({
    selectedCircle: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        backgroundColor: "black",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 15,
    },
    circle: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        backgroundColor: "darkgray",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 15,
    },
}));

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}


const BorderLinearProgress = styled(LinearProgressWithLabel)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "primary",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: "primary",
    },
}));

export default function AddPortInRequest({callback}) {
    const { t } = useTranslation();

    
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [data, setData] = useState([]);

    const [paginationData, setPaginationData] = useState(DefaultPaginationData);

    const [page, setSelectedPage] = useState(1);
    const [countries, setCountries] = useState([]);
    const [numberTypes, setNumberTypes] = useState([]);

    const [filterNumberTypes, setFilterNumberTypes] = useState(0);
    const [textFieldDoc, setTextFieldDoc] = useState({});
    const [isDocsFetched, setIsDocsFetched] = useState(false);
    const [providerCodes, setProviderCodes] = useState([]);
    const [filterProviderCode, setFilterProviderCode] = useState("");

    const [filterRoutingType, setFilterRoutingType] = useState(2);
    const [filterVoiceEndpoint, setFilterVoiceEndpoint] = useState(0);

    const [interconnections, setInterconnections] = useState([]);
    const [filterCountry, setFilterCountry] = useState("");
    const [endpoint, setEndpoint] = useState(undefined);
    const [loadingData, setLoadingData] = useState(false);
    const [filterEnabledCountry, setFilterEnabledCountry] = useState("");
    const [enabledCountries, setEnabledCountries] = useState([]);

    const [uploading, setUploading] = useState(false);

    const [portinNotes, setPortinNotes] = useState("");

    const navigate = useNavigate();


    const classes = useStyles();





    //--------------------------------------------------------
    const [identities, setIdentities] = useState([]);
    const [addresses, setAddresses] = useState([]);

    const [filterIdentity, setFilterIdentity] = useState(undefined);
    const [filterAddress, setFilterAddress] = useState(undefined);

    const [complianceRequirements, setComplianceRequirements] = useState(undefined);
    const [textFieldValues, setTextFieldValues] = useState({});
    const [fileData, setFileData] = useState([]);

    const [portingSettingsIdState, setPortingSettingsId] = useState(undefined);

    useEffect(() => {
        
    }, [fileData]);

    useEffect(() => {
    }, [textFieldValues]);
  



    const fetchIdentities = () => {
        const params = {
            pagination:2
        };
        IdentityService.listIdentities(params)
            .then((response) => {
                if (response.status === 200) {
                    const items = [];
                    response.data.data.forEach((item) => {
                        items.push({ code: item["id"], name: item["identity_name"], label: item["identity_name"], value: item["id"], compliancy_type_id: item["compliancy_type_id"] });
                    }
                    )
                    setIdentities(items);
                } else {
                    throw new Error("Error fetching data");
                }
                
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
            })
    };

    const fetchAddresses = () => {
        const params = {
            pagination:2
        };
        IdentityService.listAddresses(params)
            .then((response) => {
                if (response.status === 200) {
                    const items = [];
                    response.data.data.items.forEach((item) => {
                        items.push({ value: item["id"], address_line_1: item["address_line_1"], city: item["city"], country: item["country"], label: item["address_line_1"] + ", " + item["city"] + ", " + item["country"] });
                    }
                    )
                    setAddresses(items);
                } else {
                    throw new Error("Error fetching data");
                }
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
            })
    };

    const fetchComplianceRequirementsProduct = (portinSettingsId) => {
        setComplianceRequirements(undefined);
        const params = {
            identity_id: filterIdentity,
            porting_settings_id: portinSettingsId ? portinSettingsId : portingSettingsIdState,
        };

        IdentityService.fetchComplianceRequirements(params)
            .then((response) => {
                if (response.status === 200) {
                    setComplianceRequirements(response.data.data);
                    if(response.data.data != undefined && response.data.data.restricted == true){
                        setMessage("This product is restricted for this identity");
                        setSnackbarStatus(true);
                        return;
                    }
                } else {
                    throw new Error("Error fetching data");
                }
                
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
            })
    };


    useEffect(() => {
        fetchIdentities();
        fetchAddresses();
        return () => {
            setData([]);
        }
    }
    , []);


    const generatePayloadFromState = () => {
        const payload = {
            porting_settings_id: portingSettingsIdState,
            customer_notes: portinNotes,
            numbers: textFields,
            identity_id: filterIdentity,
            address_id: filterAddress,
            compliances: [],
        };

        fileData.forEach((item) => {
            payload.compliances.push({
                'id': item.id,
                'type': 1,
                'data_file': {
                    'name': item.data_file.name,
                    'extension': item.data_file.extension,
                    'mime_type': item.data_file.mime_type,
                    'data': item.data_file.data
                }
            });
        }
        );


        Object.keys(textFieldsDataCompliance).forEach((key) => {
            payload.compliances.push({
                'id': parseInt(key),
                'type': 2,
                'data_text': {
                    'value': textFieldsDataCompliance[key]
                }
            });
            }
        );

        if(filterRoutingType == 2){
            payload['country'] = filterCountry;
            payload['number_type'] = filterNumberTypes;
            payload['trunk_id'] = endpoint;
            payload['provider_code']= filterProviderCode ? filterProviderCode : undefined;
            payload['route_type']= 2;
            payload['porting_type']= 1;
            
        }else{
            payload['country'] = filterCountry;
            payload['number_type'] = filterNumberTypes;
            payload['trunk_id'] = undefined;
            payload['tdm_country'] = filterEnabledCountry;
            payload['provider_code'] = filterProviderCode ? filterProviderCode : undefined;
            payload['route_type'] = 3;
            payload['porting_type'] = 1;
        }
        

        return payload;
    };


    const sendPortInRequest = (goTranslation) => {

        if(filterIdentity == undefined || filterIdentity == null || filterAddress == undefined || filterAddress == null || filterIdentity == 0 || filterAddress == 0){
            setMessage("Please select an identity and address");
            setSnackbarStatus(true);
            return;
        }


        if(complianceRequirements != undefined && complianceRequirements.restricted == true){
            setMessage("This product is restricted for this identity");
            setSnackbarStatus(true);
            return;
        }

        if(portingSettingsIdState == undefined || portingSettingsIdState == null){
            setMessage("No porting settings found for this request");
            setSnackbarStatus(true);
            return;
        }


        if (textFields.length === 0 || textFields.some(text => text === "")) {
            setMessage("Please enter number.");
            setSnackbarStatus(true);
            return;
        }

        if (new Set(textFields).size !== textFields.length) {
            setMessage("Duplicate numbers are not allowed");
            setSnackbarStatus(true);
            return;
        }

        
        if (textFields.some(text => text != parseInt(text, 10))) {
            setMessage("Please enter valid digits as number.");
            setSnackbarStatus(true);
            return;
        }

        const countryCodeFromIso = countries.find(country => country.iso_code_2digit === filterCountry);
        let countryCode = countryCodeFromIso.code;

        countryCode = countryCode.replace('+', '');
        console.log(countryCode);
        if (textFields.some(text => !text.startsWith(countryCode))) {
            setMessage("Please enter number starting with country code.");
            setSnackbarStatus(true);
            return;
        }


        
        const payload = generatePayloadFromState();

        let missingFields = [];

        if(complianceRequirements != undefined && complianceRequirements.fields.length > 0){
            complianceRequirements.fields.forEach((item) => {
                if(item.field_status == 2 && item.field_type == 1){
                    let found = false;
                    payload.compliances.forEach((compliance) => {
                        if(compliance.id == item.id && compliance.type == 1){
                            found = true;
                        }
                    });
                    if(!found){
                        missingFields.push(item.display_text);
                    }
                }else if(item.field_status == 2 && item.field_type == 2){
                    let found = false;
                    payload.compliances.forEach((compliance) => {
                        if(compliance.id == item.id && compliance.type == 2){
                            found = true;
                        }
                    });
                    if(!found){
                        missingFields.push(item.display_text);
                    }
                }
            });
        }

        
        if(missingFields.length > 0){
            setMessage("Please fill in the following fields: " + missingFields.join(", "));
            setSnackbarStatus(true);
            return;
        }

        const textFieldsArray = [];

        for (const key in textFieldsDataCompliance) {
            const value = textFieldsDataCompliance[key];
            textFieldsArray.push({
                key: key,
                value: value,
            });
        }

        if(activePage == 0){
            setActivePage(1);
            return;
        }

        setUploading(true);

        PortingService.add(payload)
            .then((response) => {
                if (response.data.status === true) {
                    setUploading(false);
                    setMessage("Porting request has been succesfully processed");
                    setSnackbarStatus(true);
                    if(goTranslation){
                        const detailsID = response.data.data.id;
                        navigate(`/porting/translate/${detailsID}`);
                    }else{
                        setMessage("Porting request has been succesfully sent");
                        setSnackbarStatus(true);
                        callback(0);
                    }
                } else {
                    throw {message:"Request could not be sent", response:response};
                }
            })
            .catch((err) => {
                setMessage(err.response.data.meta.msg);
                setSnackbarStatus(true);
                setUploading(false);
            })
    }


    const fetchFields =  async (value) => {
        setIsDocsFetched(false);
        if (filterCountry == undefined || filterCountry == null || filterCountry == 0 ){
            return;
        }
        const params = {
            porting_type: 1,
            number_type: value,
            country: filterCountry,
        };

        setPortingSettingsId(undefined);

        PortingService.listSettings(params)
            .then((response) => {
                setTextFieldDoc([]);
                setComplianceRequirements(undefined);
                if(response.data.meta.code == 200){
                    setIsDocsFetched(true);
                    setTextFieldDoc(response.data);
                    setIsDocsFetched(true);
                    fetchComplianceRequirementsProduct(response.data.data.porting_settings_id);
                    setPortingSettingsId(response.data.data.porting_settings_id);
                }else{
                    if(response.data.meta.msg == 'Missing required fields'){
                        setMessage('Please select DID Type');
                        setSnackbarStatus(true);
                    }else{
                        setMessage(response.data.meta.msg);
                        setSnackbarStatus(true);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
            })
    }

    const fetchCountries = () => {
        CommonService.getCountries({porting_enabled:true})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ code: item[1]["code"], name: item[1]["name"], iso_code_2digit: item[1]["iso_code_2digit"] });
                })
                setCountries(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchDIDTypes = () => {
        let params = {
            country: filterCountry ? filterCountry == "0" ? undefined : filterCountry : undefined,
        }

        PortingService.listPortingDidTypes(params)
            .then((response) => {
                if (response.data.data == undefined || response.data.data == null ) {
                    setNumberTypes([]);
                }else{
                    let items = [];
                    Object.entries(response.data.data).forEach((item) => {
                        items.push({ value: item[1]["id"], label: item[1]["name"] });
                    });
                    setNumberTypes(items);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchProviderCodes = () => {
        setProviderCodes([]);
        NumberService.getProviderCodes({
            country: filterCountry,
            number_type: filterNumberTypes,
            porting_type:1,
            show_only_portin_enabled:true
        })
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ value: item[1]["code"], label: item[1]["code"]});
                })
                setProviderCodes(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };


    useEffect(() => {
        fetchCountries();
        fetchDIDTypes();
        fetchProviderCodes();
        fetchInterconnection();
        getEnabledCountries();
        return () => {
            setData([]);
        }
    }, [paginationData]);


    useEffect(() => {
        fetchProviderCodes();
        fetchFields(filterNumberTypes);
        return () => {
            setData([]);
        }
    }, [filterCountry, filterNumberTypes]);


    useEffect(() => {
        fetchDIDTypes();
    }, [filterCountry]);

    useEffect(() => {
        fetchComplianceRequirementsProduct();
    }, [filterIdentity]);


        const [textFieldsDataCompliance, setTextFieldsDataCompliance] = useState({});


        const handleTextFieldChangeCompliance = (event, id) => {
            setTextFieldsDataCompliance((prevValues) => ({
                ...prevValues,
                [id]: event,
            }));
        };


      
        const handleFileChangeCompliance = (event, id) => {
            const file = event.target.files[0];
    
            const selectedFile = event.target.files[0];
            const reader = new FileReader();
      
              reader.readAsDataURL(selectedFile);
      
              reader.onload = () => {
                  const base64 = reader.result;
                  const file = {
                      id: id,
                      data_file: {
                          data: base64,
                          name: selectedFile.name,
                          extension: selectedFile.name.split('.').pop(),
                          mime_type: selectedFile.type
                      }
                  };
                  setFileData((prevFiles) => [...prevFiles.filter(file => file.id !== id), file]);
              }
    
        };



    const [uploadProgressMap, setUploadProgressMap] = useState({});


    const [uploadedFileInfo, setUploadedFileInfo] = useState([]);


    const [selectedFile, setSelectedFile] = useState([]);
    const [isUploading, setIsUploading] = useState(false);



    const handleFileChange = (label) => (event) => {
        const selectedFile = event.target.files[0];
        const fileWithLabel = { file: selectedFile, label: label };

        setSelectedFile((prevSelectedFiles) => [...prevSelectedFiles, fileWithLabel]);
    };

    const updateUploadProgress = (label, percentage) => {
        setUploadProgressMap((prevProgressMap) => ({
            ...prevProgressMap,
            [label]: percentage,
        }));
    };

    const uploadFiles = async () => {
        setIsUploading(true);
        setMessage("Uploading Files");
        setSnackbarStatus(true);
        try {
            const promises = selectedFile.map((file) => {
                const form = new FormData();
                form.append('files', file.file);
                const onUploadProgress =   {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let precentage = Math.floor((loaded * 100) / total);
                        updateUploadProgress(file.label, precentage);
                    }
                }

                return NumberService.uploadFilesBuyNumber(form, onUploadProgress)
                    .then((response) => {
                        if (response.status === 200) {
                            if (response.data.meta.code === 200) {
                                setMessage("File uploaded");
                                setSnackbarStatus(true);

                                const uploadedFiles = response.data.data;
                                const guidsByLabel = [];

                                uploadedFiles.forEach((uploaded) => {
                                    guidsByLabel.push({
                                        guid: uploaded.guid,
                                        document: file.label,
                                    });
                                });
                                return guidsByLabel;
                            }
                        } else {
                            throw new Error("Error uploading file");
                        }
                    })
                    .catch((err) => {
                        setMessage(err.toString());
                        setSnackbarStatus(true);
                    });
            });

            const results = await Promise.all(promises);
            const mergedGuidsByLabel = results.reduce((acc, curr) => acc.concat(curr), []);

            setUploadedFileInfo(mergedGuidsByLabel);
            console.log(mergedGuidsByLabel);
            setMessage("All Files Uploaded");
            setSnackbarStatus(true);
        } catch (error) {
            console.error(error);
            setMessage(error.message.toString());
            setSnackbarStatus(true);
        } finally {
            setIsUploading(false);
        }
    };


    const [textFields, setTextFields] = useState([""]);


    const getEnabledCountries = () => {
        CommonService.getCountries({outbound_enabled:true})
            .then((response) => {
                let items = [];
                response.data.data.map((item) => {
                    items.push({ iso_code_2digit: item.iso_code_2digit, name: item.name });
                });
                setEnabledCountries(items);
                if (items.length > 0) {
                    setFilterEnabledCountry(items[0].iso_code_2digit);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const fetchInterconnection = () => {
        setLoadingData(true);
        NumberService.listInterconnection()
            .then((response) => {
                setInterconnections(response.data.data.items);
                setEndpoint(response.data.data.items[0]['id']);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    const [activePage, setActivePage] = useState(0);


    const removeFile = (fileName) => {
        setSelectedFile(prevFiles => prevFiles.filter(file => file.file.name !== fileName));
    };



    const handleAddTextField = () => {
        setTextFields([...textFields, ""]);
    };

    const handleChangeTextField = (index, value) => {
        const updatedFields = [...textFields];
        updatedFields[index] = value;
        setTextFields(updatedFields);
    };

    const changeFilterType = async (value) => {
        setFilterNumberTypes(value);
        await fetchFields(value);
    }

    const [store, dispatch] = useStore();
    const providersEnabled = store.portalSettings.services.access_management.display_provider_codes ?? false;

    return (
        <>
        <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
        <BaseCard title={t('Port-In Request')}>
            {activePage === 0 && (<TableFilterContainer>
            <Grid container padding={2} spacing={1} direction={"column"} xs={3}>
            <Grid item xs={3} container alignItems="center" justifyContent="flex-start">
                        <FormControl fullWidth>
                        <BaseSearchableSelect label={"Identity"} options={identities} state={filterIdentity} setState={setFilterIdentity} key={'filterIdentity'} />
                        </FormControl>
                        <Typography sx={{ textAlign: 'left', marginTop: 1 }}>
                                    {identities.find(item => item.value == filterIdentity) ? identities.find(item => item.value == filterIdentity).compliancy_type_id == 1 ? "Company Identity Selected" : "Personal Identity Selected" : ""}
                        </Typography>
                </Grid>
                <br/>
                <Grid item xs={4} container alignItems="center" justifyContent="flex-start">
                        <BaseSearchableSelect label={"Address"} options={addresses} state={filterAddress} setState={setFilterAddress} key={'filterAddress'} />
                    <br/>
                </Grid>
                </Grid>
                <br/>
                <>
                <Grid container padding={2} spacing={2}>
                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{t('country')}</InputLabel>
                            <Select
                                label={'country'}
                                labelId="filter-country-label"
                                name="country"
                                color="secondary"
                                value={filterCountry }
                                onChange={event => { setFilterCountry(event.target.value) }}
                            >
                                {countries.map((country, index) => {
                                    return <MenuItem key={country.code} value={country.iso_code_2digit}>{country.name}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item  >
                    {textFields.map((text, index) => (
        <Grid container direction="row" alignItems="center" key={index} spacing={1}>
            <Grid item>
                <TextField
                    value={text}
                    label="Number"
                    name="Number"
                    multiline
                    rowsMax={3}
                    variant="outlined"
                    sx={{ marginBottom: 1, backgroundColor: 'white', borderRadius: '10px' }}
                    onChange={e => handleChangeTextField(index, e.target.value)}
                />
            </Grid>
            <Grid item>
                {index > 0 && (
                    <BaseButton sx ={{marginBottom: 1}} onClick={() => {
                        const updatedFields = [...textFields];
                        updatedFields.splice(index, 1);
                        setTextFields(updatedFields);
                    }}>
                        Remove
                    </BaseButton>
                )}
            </Grid>
        </Grid>
    ))}
                            <Grid item>
                                <BaseButton variant="contained" color="--color-primary" onClick={handleAddTextField}>
                                    Add
                                </BaseButton>
                            </Grid>
                    </Grid>

                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-type-label">{'Type'}</InputLabel>
                            <Select
                                label={'type'}
                                labelId="filter-type-label"
                                name="type"
                                color="secondary"
                                value={filterNumberTypes}
                                onChange={event => { changeFilterType(event.target.value) }}
                            >
                                {getSelectOptions(numberTypes)}
                            </Select>
                        </FormControl>
                    </Grid>


                    {providersEnabled && (<Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-provider-label">{"Prefered Provider"}</InputLabel>
                            <BaseSelect
                                label={"Provider"}
                                labelId="filter-provider-label"
                                name="provider"
                                color="secondary"
                                value={filterProviderCode}
                                onChange={event => { setFilterProviderCode(event.target.value) }}
                            >
                                {getSelectOptions(providerCodes)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>)}


                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <BaseTextField
                                value={portinNotes}
                                label={t('Notes')}
                                name="notes"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={setPortinNotes}
                            />
                        </FormControl>
                    </Grid>


                    </Grid>


                    {complianceRequirements != undefined &&  complianceRequirements.descriptions != null && complianceRequirements.descriptions != undefined && (
                         <>
                         <fieldset style={{ border: '1px solid #ccc', padding: 30, borderRadius: 10, textAlign: 'left' }}>
                                            <legend>Descriptions</legend>
                        <>
                            {complianceRequirements.descriptions.map((item, index) => (
                            <>
                                <Grid direction={'column'} alignItems="left">
                                        <Typography sx={{ textAlign: 'left' }}>
                                        {item.description_header}</Typography>
                                            {item.descriptions.map((descr, index) => (
                                                <li key={index} style={{textAlign: 'left'}}>{descr}</li>
                                            ))}
                                    </Grid>
                                    </>
                                    ))}

                            </>  
                    </fieldset>

                    </>
                        )}


                    <br/>

                    {complianceRequirements && complianceRequirements?.template_files?.length > 0 && (
                                                <TemplateFilesForm template_files= {complianceRequirements.template_files} setMessage = {setMessage} setSnackbarStatus = {setSnackbarStatus} />
                                                )}

                    {complianceRequirements != undefined && complianceRequirements.fields.length > 0 ? (
                    <>

                    <fieldset style={{ border: '1px solid #ccc', padding: 30, borderRadius: 10, textAlign: 'left' }}>
                                        <legend>Requirements</legend>
                    {complianceRequirements.fields.map((item, index) => (
                        <>
                        <br/>
                        <Grid container direction={'row'} alignItems="left" justifyContent="flex-start">
                                <Grid item xs={1} container alignItems="center" justifyContent="flex-start">

                                <Typography sx={{ textAlign: 'left' }}>
                                    {item.display_text}</Typography>
                                    </Grid>

                                    <Grid item xs={4} container alignItems="center" justifyContent="flex-start">


                                {item.field_status == 0 && (
                                    <Typography sx={{ textAlign: 'left', color: 'orange' }}>
                                        {'Pending validation'}
                                    </Typography>)
                                }

                                {item.field_status == 1 && (
                                    <Typography sx={{ textAlign: 'left', color: 'green' }}>
                                        {'Already verified'}
                                    </Typography>)
                                }


                                {item.field_status == 2 && item.field_type == 1 && (
                                        <>
                                        <Grid container alignItems="center" justifyContent="left">
                                            <BaseButton>
                                                <input
                                                    accept={'.' + item.allowed_extensions.join(', .')}
                                                    id={item.id}
                                                    type="file"
                                                    onChange={(event) => handleFileChangeCompliance(event, item.id)}
                                                    style={{ height: 35, alignContent: 'center' }}
                                                />
                                            </BaseButton>
                                        </Grid>
                                    </>
                                )
                                }

                                {item.field_status == 2 && item.field_type == 2 && (
                                        <BaseTextField
                                        sx = {{width: '100%'}}
                                            key={item.id}
                                            value={null}
                                            name="number"
                                            margin="normal"
                                            variant="outlined"
                                            color="secondary"
                                            onChange={event => handleTextFieldChangeCompliance(event, item.id)}
                                        />
                                )
                                }

                                </Grid>


                                <Grid item xs={3} container alignItems="center" justifyContent="center">
                                {item.field_status == 2 && item.reject_comment != undefined && item.reject_comment != null && item.reject_comment != "" && (
                                
                                <Typography sx={{ textAlign: 'left', color: 'red' }}>
                                    {item.reject_comment}
                                </Typography>)
                                }  

                                </Grid>

                                <br/>
                                </Grid>
                            
                                </>
                                
                    ))}
                    <br/>
                        <Typography sx={{ textAlign: 'left', color: 'red'}}>
                                    {"* All fields are required"}  
                                </Typography>

                        
                        </fieldset>
                        </>
                    ) : (
                        <></>
                    )}

                    </>

                    <br/>


                    <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                            <SearchButtonContainer item >
                            <BaseButton sx={{ marginRight: 1 }}
                                    label={'Cancel'}
                                    onClick={() => callback(0)} color={"primary"}
                                />

                             <BaseButton
                                    label={'Submit'} sx={{ marginRight: 1 }}
                                    onClick={() => sendPortInRequest(false)} color="--color-primary"
                                />
                            </SearchButtonContainer>
                        </Grid>

            </TableFilterContainer>)}

            {activePage === 1 && (
                
                <>
                <TableFilterContainer>
                    <Grid container direction="column" alignItems="left" justifyContent="left" xs={3} justifyItems="left" spacing={2}>
                        <Grid item xs={3} container alignItems="center" justifyContent="flex-start">
                            <FormControl fullWidth>
                                <InputLabel id="filter-status-label">{"Routing Type"}</InputLabel>
                                <Select
                                    label={'Routing Type'}
                                    labelId="filter-country-label"
                                    name="country"
                                    color="secondary"
                                    value={filterRoutingType }
                                    onChange={event => { setFilterRoutingType(event.target.value) }}
                                >
                                    {[
                                        {value: 2, text: 'IP'},
                                        {value: 3, text: 'PSTN'},
                                    ].map((item, index) => {
                                        return <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>;
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3} container alignItems="center" justifyContent="flex-start">

                        {filterRoutingType == 2 ? (<FormControl fullWidth>
                            <InputLabel id="filter-provider-label">{"Voice Endpoint"}</InputLabel>
                            <BaseSelect
                                label={"Voice Endpoint"}
                                labelId="filter-provider-label"
                                name="provider"
                                color="secondary"
                                value={endpoint}
                                onChange={event => { setEndpoint(event.target.value) }}
                            >
                                {interconnections.map((item, index) => {
                                    return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>;
                                })}
                            </BaseSelect>
                        </FormControl>) :  (<FormControl fullWidth>
                            <InputLabel id="filter-provider-label">{"Enabled Countries"}</InputLabel>
                            <BaseSelect
                                label={"Enabled Countries"}
                                labelId="filter-country-label"
                                name="country"
                                color="secondary"
                                value={filterEnabledCountry}
                                onChange={event => { setFilterEnabledCountry(event.target.value) }}
                            >
                                {enabledCountries.map((item, index) => {
                                    return <MenuItem key={item.iso_code_2digit} value={item.iso_code_2digit}>{item.name}</MenuItem>;
                                })}
                            </BaseSelect>
                        </FormControl>)}
                        </Grid>



                        <Grid item  container alignItems="center" justifyContent="flex-start" direction={"row"}>
                            <LoadingButton loading={uploading} onClick={() => { sendPortInRequest(false)}} color={"primary"} margin="normal" variant="contained" component="span" sx={{ marginRight: 1 }}>
                                {'Submit'}
                            </LoadingButton>

                            <LoadingButton loading={uploading} onClick={() => { sendPortInRequest(true)}} color={"primary"} margin="normal" variant="contained" component="span" sx={{ marginRight: 1 }}>
                                {'Submit and Configure Translation'}
                            </LoadingButton>
                        </Grid>


                    </Grid>


                </TableFilterContainer>

                </>)}

        </BaseCard>
        </>
    );
}
